export const languageHashValues= {
  "GLOBAL": ".286e6d5372c5e972fbd8",
  "U4302_TRANSACTIONSDETAIL": ".2183d4d1b9bea83b0315",
  "U4400_PAYMENT": ".96386517c6760940f0b4",
  "U5122_INVOICEMODIFICATIONAPPROVAL": ".0153b108a5f73fc3ae8f",
  "U2117_PADAGREEMENTINVITATION": ".7612ac45a25aab0802b5",
  "U2000_PAD": ".6a091e10a4736a53e336",
  "U4402_INVOICEPORTALPAYMENT": ".be88830a3ee2c0a4ab2f",
  "U2000_BANKACCOUNTCREATION": ".8656733647b808d0c137",
  "U2011_LOGIN": ".41c46d1ca286d6a160d4",
  "U2012_LOGINHELP": ".b47c74a337e34cb1af24",
  "U2014_SECURITY": ".09b555e4f3c1dc8d6048",
  "U2086_EFTPENDINGPAYMENTS": ".7aa31aa0aa18682e21b0",
  "U2087_EFTPAYMENTSNOTIFICATIONS": ".4ee62172639e5d1c83a2",
  "U2110_ACCOUNTCREATION": ".5ce9a31455c09baa4097",
  "U2121_INDIVIDUALPROFILE": ".2913e8fd07894ea319ef",
  "U2131_ORGANIZATIONPROFILE": ".2933dcd295015fcaa6bd",
  "U2112_ERPBINDING": ".fed1ee45ea92d7cd3d3b",
  "U2113_SUPPLIERINVITATION": ".1ce87babdd65d881e570",
  "U2118_RBBINDING": ".5e795e7861cc724b6d3b",
  "U2132_AFFILIATION": ".0260d1dec51b589c25d6",
  "U2133_BUSINESSLINKS": ".b1b19b71fcffe85c0f9b",
  "U2181": ".08a4cec464676895ebcf",
  "U2211_SUBSCRIPTIONOFFER": ".4e5dd7ff6cac6fb94779",
  "U2212_SUBSCRIPTIONFILTER": ".6e060feb4edf175f421d",
  "U2213_SUBSCRIPTION": ".1333681a14009fe953a1",
  "U4111_CREDITCARDADDITION": ".7f7eb727aa75eec3aba8",
  "U4112_CREDITCARDDETAIL": ".dbc1bbf0aedaa2947d9e",
  "U4121_BANKACCOUNTADDITION": ".370328860ebc8ffbe479",
  "U4122_BANKACCOUNTDETAIL": ".7f7ed6db6eb12dcf9a74",
  "U4303_ANONYMOUSTRANSACTION": ".dea54f519e48183ee509",
  "U4401_PAYMENTGATEWAY": ".6f350d8a52a38bf4dd47",
  "U4481_PROCESSINVOICEINSTALMENTPAYMENTS": ".ab3bede73e0d426966cf",
  "U5000_RECEIVABLE": ".dd993747e05117a96c99",
  "U5101_STATEMENTOFACCOUNT": ".f1b1e81a01de67bb523d",
  "U5102_INVOICE": ".ae1ad40adefaa773668b",
  "U5103_PADAGREEMENT": ".95d8d588da6e1df2bea0",
  "U5121_SENTINVOICE": ".db7a16e627d61a6a4ae6",
  "U5124_PGRECEIVEDPAYMENT": ".2165d0807b1180505199",
  "U5120_INVOICES": ".aee88caf9b67bfa0d790",
  "U5123_PADAGREEMENTCONSOLE": ".e1a14d88f325d572c46d",
  "U5132_PAYMENTRECONCILIATION": ".3c41a17b6ee78ec2c5d7",
  "U5133_BANKRECONCILIATION": ".8745072c8e33d2d432a9",
  "U6112_BUSINESSLINK": ".470f49b43c4bcfae58a2",
  "U6113_SUPPLIERRECORD": ".55f94dd7098833cd5c57",
  "U6211_PAYLOT": ".65db8ff59d415a397f68",
  "U6311_PURCHASE": ".5177c49798b3d013a900",
  "U2115_AFFILIATIONINVITATION": ".3cae47ece1f778a2a32e",
  "U6100_PAYABLE": ".20808ac3862de634aa3f",
  "U6131_SENTPAYMENT": ".a80451c9b6250da8aba1",
  "U6191_SENTPAYMENTREPORT": ".5ab84f5d4cb487800615",
  "U6221_SENTPAYMENT": ".00b7c9a66583262e0e70",
  "U6422_INTERACSENTPAYMENT": ".f12cee3d459fa905472e",
  "U6531_TRANSACTIONPAYMENT": ".1f12845fb83e17af8677",
  "U5131_RECEIVEDPAYMENT": ".e23c6a3695a1c1beef73",
  "U5191_RECEIVEDPAYMENTREPORT": ".f52c41b84679de5c7cc2",
  "U2001_HOME": ".2df7a241f959b3899509",
  "U2024_REPORTLOG": ".9e21f085b82687adb1c8",
  "U2111_INVOICEPORTALBINDING": ".2b55a9721764fd6bc8a4",
  "U2116_ANONYMOUSSUPPLIERINVITATION": ".434a24b3ef3cabcaead1",
  "U6111_SUPPLIERINVITATIONCONSOLE": ".d8027fbac2fdd0ada488",
  "U6511_TRANSACTIONPARTY": ".1260205084c9fc3b5465",
  "U6212_PAYROLLPARTY": ".49fd29c01ceb2b8bcc51",
  "U6121_EFTLOT": ".2dfcf5849e8e944dd939",
  "U6412_INTERACFILE": ".9826b9562f14c675e50a",
  "U6122_EFTAPPROVAL": ".d9ec96b8801cd6fdcd43",
  "U6512_TRANSACTIONLOT": ".b0da66941a4e2064f11a",
  "U6123_EFTAPPROVAL": ".99170c12f2a4421f7506",
  "U6123_EFTAPPROVALMOBILE": ".9af4d2a318db6c7c077f",
  "U2012_LOGIN": ".9c40150d306cfeb77550",
  "U2000_PAYMENT": ".af6def9c1124f3c6c3fa",
  "U4101_CREDITCARDBANKACCOUNTSUMMARY": ".4dc728f2259170c1238d",
  "U2000_PAYMENTMODEUPDATE": ".df6f94d2095fdfc1d2fb",
  "U6114_TELUSBILLER": ".5f8041df220cf4415bec",
  "U4403_CREDITCARDTERMINAL": ".f9b7e43985413c86a4c2",
  "U4404_INTERACTERMINAL": ".8f946565de6b798b35a7",
  "U2000_SAMPLECHEQUESELECTION": ".9b16e1ef39b46c6ce958",
  "U2023_TUTORIALVIDEO": ".f757afcc70cfbf4aba15",
  "U2134_ORGANIZATIONBILLING": ".1b63a131f95405784566",
  "U6414_BANKACCOUNTWALLET": ".bc14787a61833f3b6109",
  "U2022_INSTANTACCOUNTVERIFICATION": ".6924f26eef9dcbc1a2b3",
  "U6532_PENDINGTRANSACTION": ".5711aba0889ab773b2b9",
  "U4301_TRANSACTIONSSUMMARY": ".e062fcee959d2d42c1c8",
  "U5111_SENTINVOICESTRACKING": ".35039991bfe14671fad8",
  "U2026_NETPROMOTERSCORE": ".359e8689ea1ae1248e08",
  "U2000_ACCOUNTPERSONNALINFORMATIONS": ".61558e2895b06a17ca18",
  "U2000_INTRO": ".9df6444ec15fb801af69",
  "U2000_ELECTRONICSIGNATURE": ".114fe7216f3ccf872072",
  "U2000_VERIFYEMAIL": ".da867dc9b3ea2d8c7652",
  "U2200_SUBSCRIPTION": ".02048cfda67b66fbba95",
  "U2000_VERIFYIDENTITY": ".caaee4f850953b995386",
  "U2000_ATTACHMENTVIEWER": ".959a5c3b359f14f26261",
  "U2212_PLANLIST": ".60c61d7211381679ac58",
  "U3002_TAXBILLSEARCHRESULT": ".eabdc729fa7e86e9fe23"
}